import * as Sentry from '@sentry/gatsby';
import { browserTracingIntegration, replayIntegration } from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://4a625aaf514236c4426aa77c73d17364@o4507933253173248.ingest.de.sentry.io/4507933255467088',
  integrations: [browserTracingIntegration(), replayIntegration()],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/((www|staging)\.)?giga-hamburg\.de\/api/],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
